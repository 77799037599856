<template>
  <div class="layout">
    <!--头部 -->
    <div class="header">
      <div class="nav">
        <div class="logo">
          <img :src="imgUrlLogo" title="Gfast官网">
        </div>
        <div class="menu-toggle" @click.stop="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="menubox" :class="{ 'menu-open': isMenuOpen }">
          <ul>
            <li :class="{ 'on': $route.path === '/' }">
              <a @click.prevent="$router.push({path:'/'})">首页<span></span></a>
            </li>
            <li>
              <a href="http://v3.g-fast.cn/sys" target="_blank">在线演示<span></span></a>
            </li>
            <li>
              <a href="http://doc.g-fast.cn" target="_blank">在线文档<span></span></a>
            </li>
            <li>
              <a href="https://gitee.com/tiger1103/gfast" target="_blank">源码下载<span></span></a>
            </li>
            <li>
              <a href="http://www.qjit.cn" target="_blank">定制开发<span></span></a>
            </li>
            <li>
              <a href="http://www.qjit.cn/list/1.html" target="_blank">关于我们<span></span></a>
            </li>
            <!-- <li><a>腾讯云特惠<span></span></a></li>
            <li><a>阿里云特惠<span></span></a></li> -->
            <li v-if="!isLogin">
              <a @click.prevent="$router.push({path:'/personalCenter/personalCenter'})">会员中心<span></span></a>
            </li>
            <li v-else class="avatar-img">
              <el-popover placement="bottom" width="100" v-model="visible" trigger="hover">
                <ul class="avatar-menu">
                  <li @click.prevent="jump('/personalCenter/personalCenter')">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-huiyuanzhongxin"></use>
                    </svg> 会员中心
                  </li>
                  <li @click="logout">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-tuichu2"></use>
                    </svg> 注 销
                  </li>
                </ul>
                <img slot="reference" :src="userInfo.memPic || '/img/default_avatar.png'">
              </el-popover>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <app-main />
    <!--底部-->
    <div class="footer">
      <div class="container">
        <div class="footer_l">
          Copyright @ 2020-2028 云南奇讯科技有限公司 版权所有
          <a href="http://www.beian.miit.gov.cn/" target="_blank">滇ICP备16002997号-5</a><br> 友情连接：
          <a href="www.qjit.cn" target="_blank">挂图作战系统</a>
          <a href="www.qjit.cn" target="_blank">宏观数据库平台</a>
          <a href="www.qjit.cn" target="_blank">公租房管理系统</a>
          <a href="www.qjit.cn" target="_blank">二手车管理系统</a>
          <a href="www.qjit.cn" target="_blank">曲靖软件公司</a>
          <a href="www.qjit.cn" target="_blank">曲靖网站建设</a>
          <a href="www.qjit.cn" target="_blank">曲靖小程序开发</a>
        </div>
        <div class="footer_r">
          <div class="footer_ht">服务热线：0874-3331516 </div>
          <div class="weixin"><img :src="imgUrlWx"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinImgUrl from '@/utils/mixin/imgUrl.js'
import AppMain from './components/AppMain.vue'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  computed: {
    ...mapState({
      isLogin: state => state.user.token,
      userInfo: state => state.user.userInfo
    })
  },
  data () {
    return {
      visible: false,
      isMenuOpen: false
    }
  },
  watch: {
    $route () {
      // 路由变化时关闭菜单
      this.isMenuOpen = false
    }
  },
  methods: {
    jump (path) {
      this.$router.push({ path: path })
      this.visible = false
      this.isMenuOpen = false
    },
    logout () {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload()
        })
      })
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu () {
      // 添加关闭菜单的方法
      this.isMenuOpen = false
    }
  },
  mounted () {
    // 添加点击事件监听，点击导航外部时关闭菜单
    document.addEventListener('click', (e) => {
      const menubox = this.$el.querySelector('.menubox')
      const menuToggle = this.$el.querySelector('.menu-toggle')
      if (menubox && menuToggle && this.isMenuOpen) {
        if (!menubox.contains(e.target) && !menuToggle.contains(e.target)) {
          this.closeMenu()
        }
      }
    })
  },
  beforeDestroy () {
    // 组件销毁前移除事件监听
    document.removeEventListener('click', this.closeMenu)
  },
  components: {
    AppMain
  },
  mixins: [mixinImgUrl]
}
</script>

<style lang="less" scoped>
@import "~@/assets/css/module.css";
@import "~@/assets/css/public.css";
@import "~@/assets/css/responsive.css";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .menu-toggle {
      display: none;
      width: 30px;
      height: 24px;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      padding: 10px;
      margin-right: 10px;

      span {
        display: block;
        height: 2px;
        width: 100%;
        background-color: #fff;
        transition: all 0.3s ease;
      }
    }

    @media screen and (max-width: 768px) {
      .menu-toggle {
        display: flex;
      }

      .menubox {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.95);
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: 100;

        &.menu-open {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }

        ul {
          flex-direction: column;
          padding: 10px 0;

          li {
            width: 100%;

            a {
              padding: 12px 20px;
              display: block;
              text-align: left;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);

              &:hover {
                background: rgba(255, 255, 255, 0.1);
              }
            }
          }
        }
      }
    }
  }
}

.avatar-img {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 50%;
    width: 50%;
    border-radius: 50%;
    border: 0;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    padding: 10px 20px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.avatar-menu {
  width: 100%;
  li {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-indent: 10px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.footer {
  margin-top: auto;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 15px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      text-align: center;
      .footer_l, .footer_r {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
