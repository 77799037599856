<template>
  <div class="app-container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-width="isMobile ? 'auto' : '120px'"
      class="impower-form"
    >
      <el-form-item label="公司名称" prop="comName">
        <el-input
          v-model="form.comName"
          placeholder="请输入公司名称，用于标识您已购买，个人授权无需填写"
        />
      </el-form-item>
      <el-form-item label="企业代码" prop="comCode">
        <el-input
          v-model="form.comCode"
          placeholder="请输入企业信用代码，用于标识您已购买，个人授权无需填写"
        />
      </el-form-item>
      <el-form-item label="联系人" prop="linkMan">
        <el-input
          v-model="form.linkMan"
          placeholder="请输入联系人，用于标识您已购买"
        />
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="请输入联系电话，方便接收更新通知"
        />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import { addImpower } from '@/api/plugin'

export default {
  name: 'impower',
  props: {
    pluginId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      form: {},
      isMobile: window.innerWidth <= 768,
      rules: {
        linkMan: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '联系电话不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.isMobile = window.innerWidth <= 768
    },
    // 取消按钮
    cancel () {
      this.reset()
      this.$emit('exit')
    },
    // 表单重置
    reset () {
      this.form = {
        pluginId: undefined,
        comName: undefined,
        comCode: undefined,
        linkMan: undefined,
        phone: undefined
      }
    },
    submitForm () {
      this.form.pluginId = this.pluginId
      this.$refs.form.validate((valid) => {
        if (valid) {
          addImpower(this.form).then(res => {
            if (res.code === 0) {
              this.reset()
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.app-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;

  .impower-form {
    width: 100%;
  }
}

.dialog-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

:deep(.el-form-item) {
  margin-bottom: 22px;
}

:deep(.el-input) {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .app-container {
    padding: 15px;
  }

  :deep(.el-form-item) {
    margin-bottom: 15px;
  }

  :deep(.el-form-item__label) {
    float: none;
    display: block;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5;
  }

  :deep(.el-form-item__content) {
    margin-left: 0 !important;
    width: 100%;
  }

  .dialog-footer {
    justify-content: center;
    margin-top: 25px;

    .el-button {
      flex: 1;
      max-width: 140px;
      margin: 0;

      & + .el-button {
        margin-left: 10px;
      }
    }
  }
}
</style>
