<template>
  <div class="profile">
    <div class="profile-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :xs="12" :sm="12">
          <h2>个人资料</h2>
        </el-col>
        <el-col :xs="12" :sm="12" class="header-actions">
          <el-button
            icon="el-icon-unlock"
            size="small"
            type="danger"
            @click="editPassword">
            修改密码
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="profile-content">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" class="avatar-section">
          <el-upload
            class="avatar-uploader"
            :headers="headers"
            :action="BaseUrl + '/codeStoreFrontAdmin/uploadAvatar'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img :src="form.memPic || '/img/default_avatar.png'" class="avatar">
            <div class="avatar-hover">
              <i class="el-icon-camera"></i>
              <span>更换头像</span>
            </div>
          </el-upload>
        </el-col>

        <el-col :xs="24" :sm="18">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
            class="profile-form">
            <el-form-item label="用户名">
              <el-input v-model="form.memName" disabled></el-input>
            </el-form-item>

            <el-form-item label="昵称">
              <el-input v-model="form.memNickname"></el-input>
            </el-form-item>

            <el-form-item label="邮箱">
              <el-input v-model="form.memEmail" disabled></el-input>
            </el-form-item>

            <el-form-item label="手机号">
              <el-input v-model="form.memPhone" disabled></el-input>
            </el-form-item>

            <el-form-item class="form-actions">
              <el-button type="primary" @click="onSubmit">修 改</el-button>
              <el-button @click="reset">重 置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getUserInfo, updateUserInfo } from '@/api/profile.js'
import { getToken } from '@/utils/auth'

export default {
  name: 'profile',
  data () {
    return {
      headers: { Authorization: 'Bearer ' + getToken() },
      form: {
        memPic: '',
        memName: '',
        memNickname: '',
        memEmail: '',
        memPhone: ''
      },
      rules: {
        memNickname: [
          { required: true, trigger: 'blur', message: '用户昵称必须' }
        ]
      }
    }
  },
  methods: {
    editPassword () {
      this.$router.push({ path: 'editPassword' })
    },
    handleAvatarSuccess (res, file) {
      const { code, data } = res
      if (code === 0) {
        const { fileUrl } = data
        this.form.memPic = fileUrl
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          updateUserInfo(this.form).then(res => {
            const { code, msg } = res
            if (code === 0) {
              this.$message.success(msg)
            } else {
              this.$message.error(msg || '用户信息修改失败!')
            }
          })
        }
      })
    },
    reset () {
      this.form.memNickname = ''
    }
  },
  created () {
    getUserInfo().then(res => {
      const { code, data, msg } = res
      if (code === 0) {
        const {
          memPic,
          memName,
          memNickname,
          memEmail,
          memPhone
        } = data

        this.form.memPic = memPic
        this.form.memName = memName
        this.form.memNickname = memNickname
        this.form.memEmail = memEmail
        this.form.memPhone = memPhone
      } else {
        this.$message.error(msg || '获取用户信息失败!')
      }
    })
  }
}
</script>

<style lang="less" scoped>
.profile {
  padding: 20px;
  box-sizing: border-box;

  .profile-header {
    margin-bottom: 30px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    h2 {
      margin: 0;
      font-size: 24px;
      color: #303133;
    }

    .header-actions {
      text-align: right;
    }
  }

  .profile-content {
    background: #fff;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .avatar-section {
      text-align: center;
      margin-bottom: 30px;
    }

    .avatar-uploader {
      display: inline-block;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      }

      .avatar-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s;
        color: #fff;

        i {
          font-size: 24px;
          margin-bottom: 5px;
        }

        span {
          font-size: 14px;
        }
      }

      &:hover .avatar-hover {
        opacity: 1;
      }
    }

    .profile-form {
      max-width: 600px;
      margin: 0 auto;

      .form-actions {
        margin-top: 30px;

        .el-button {
          min-width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .profile {
    padding: 15px;

    .profile-header {
      padding: 15px;
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      .el-button {
        padding: 7px 15px;
      }
    }

    .profile-content {
      padding: 20px 15px;

      .avatar-uploader {
        .avatar {
          width: 100px;
          height: 100px;
        }
      }

      .profile-form {
        :deep(.el-form-item__label) {
          padding-right: 5px;
          font-size: 14px;
        }

        .form-actions {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;

          .el-button {
            flex: 1;
            margin: 0 5px;
            min-width: unset;
            padding: 10px 15px;
          }
        }
      }
    }
  }
}
</style>
