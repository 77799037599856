<template>
  <div class="personalCenter">
    <el-row class="page-header">
      <el-col :span="24">
        <h2>会员中心</h2>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="user-info">
      <el-col :xs="24" :sm="8" :md="6" class="avatar-section">
        <div class="avatar-wrapper">
          <span class="avatar-img">
            <img :src="userInfo.memPic || '/img/default_avatar.png'" alt="用户头像">
          </span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="16" :md="18" class="info-section">
        <div class="info-content">
          <h4 class="username">
            <a>{{userInfo.memName || ''}}</a>
          </h4>
          <p class="bio">这家伙很懒，什么也没写！</p>

          <el-divider></el-divider>

          <div class="info-grid">
            <el-row :gutter="20">
              <el-col :xs="12" :sm="12">
                <div class="info-item">
                  <strong>UID:</strong>
                  <span>{{userInfo.memId || 'xx'}}</span>
                </div>
              </el-col>
              <el-col :xs="12" :sm="12">
                <div class="info-item">
                  <strong>Lv:</strong>
                  <span>{{userInfo.memLeave}}</span>
                </div>
              </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-row :gutter="20">
              <el-col :xs="12" :sm="12">
                <div class="info-item">
                  <strong>积分:</strong>
                  <span>{{userInfo.memScore}}</span>
                </div>
              </el-col>
              <el-col :xs="12" :sm="12">
                <div class="info-item">
                  <strong>余额:</strong>
                  <span>{{userInfo.memBalance}}</span>
                </div>
              </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-row :gutter="20">
              <el-col :xs="24" :sm="12">
                <div class="info-item">
                  <strong>加入时间:</strong>
                  <span>{{userInfo.createdAt}}</span>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12">
                <div class="info-item">
                  <strong>上次登录:</strong>
                  <span>{{userInfo.lastLoginTime}}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'personalCenter',
  data () {
    return {
    }
  },
  created () {
    console.log(this.userInfo)
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    }
  }
}
</script>

<style lang="less" scoped>
.personalCenter {
  padding: 20px;
  box-sizing: border-box;

  .page-header {
    margin-bottom: 20px;
    h2 {
      margin: 0;
      font-size: 24px;
      color: #303133;
    }
  }

  .user-info {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;

    .avatar-section {
      .avatar-wrapper {
        text-align: center;
        padding: 10px;
      }

      .avatar-img {
        display: inline-block;
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
      }
    }

    .info-section {
      .info-content {
        padding: 10px;

        .username {
          margin: 0 0 10px;
          font-size: 20px;
          a {
            color: #303133;
            text-decoration: none;
          }
        }

        .bio {
          color: #606266;
          margin: 0 0 15px;
        }

        .info-grid {
          .info-item {
            display: flex;
            align-items: center;
            margin: 8px 0;

            strong {
              min-width: 80px;
              color: #606266;
            }

            span {
              color: #303133;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .personalCenter {
    padding: 15px;

    .page-header {
      h2 {
        font-size: 20px;
      }
    }

    .user-info {
      padding: 15px;

      .avatar-section {
        margin-bottom: 20px;

        .avatar-img img {
          width: 120px;
          height: 120px;
        }
      }

      .info-section {
        .info-content {
          padding: 0;

          .username {
            font-size: 18px;
            text-align: center;
          }

          .bio {
            text-align: center;
          }

          .info-grid {
            .info-item {
              margin: 5px 0;

              strong {
                min-width: 70px;
                font-size: 14px;
              }

              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
