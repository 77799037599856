<template>
  <div class="home">

    <!--标题搜索-->
    <div class="module-head-search">
      <div class="moduletitle">成为Gfast开发者，共同分享插件市场</div>
      <div class="moduledesc">您提供的插件必须是基于Gfast框架开发的应用插件！</div>
      <div class="modulesearch">
        <div class="search-wrapper">
          <input type="text"
                 id="registerVal"
                 v-model="query.pluginName"
                 placeholder="填写你要搜索的插件">
          <div class="module-btn" @click="getList">搜索</div>
        </div>
      </div>
    </div>
    <div class="clr"></div>
    <!--选项-->
    <div class="module-box">
      <div class="module-item">
        <ul>
          <li class="module-bott"> <span>功能：</span>
            <p>
              <a :class="['search-head', {active: query.pluginCateId == ''}]"
                 @click.prevent="searchList({pluginCateId : ''})">全部</a>
              <a v-for="item in plugincate"
                 :key="item.cateId"
                 @click.prevent="searchList({pluginCateId : item.cateId +''})"
                 :class="['search-head', {active: query.pluginCateId == item.cateId +''}]">{{item.cateName}}</a>
            </p>
          </li>
          <li> <span>价格：</span>
            <p>
              <a :class="['search-head', {active: query.priceType == ''}]"
                 @click.prevent="searchList({priceType : ''})">全部</a>
              <a :class="['search-head', {active: query.priceType == '1'}]"
                 @click.prevent="searchList({priceType : '1'})">免费</a>
              <a :class="['search-head', {active: query.priceType == '2'}]"
                 @click.prevent="searchList({priceType : '2'})">付费</a>
            </p>
            <label>
              <input type="checkbox"
                     name="isOfficial"
                     value="1"
                     @click="isOfficial">
              <font> 官方应用</font>
            </label>
          </li>
        </ul>
      </div>

      <div class="module-list">
        <ul>
          <li v-for="(item, index) in list"
              :key="index">
            <div class="mcard"
                 @click="jump(item)">
              <a><img :src="item.pluginThumb || imgUrl300x200" /></a>
              <p class="mtitle">{{item.pluginName}}</p>
              <p v-if="item.pluginDiscount!==100" class="price"><span class="yp">原价：{{item.pluginPrice | fenToYuan}}</span>&nbsp;<span>{{fenToYuanDiscount(item.pluginPrice,item.pluginDiscount)}}</span></p>
              <p v-else class="price">{{item.pluginPrice | fenToYuan}}</p>
              <P class="update">{{item.created_at}}</P>
              <P class="download">下载：{{item.downloadTimes || 0}}次</P>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-row :gutter="10"
            style="margin-bottom:20px;"
            type="flex"
            justify="center">
      <el-col :span="6">
        <el-pagination background
                       v-show="total > query.pageSize"
                       layout="prev, pager, next"
                       :page-size="query.pageSize"
                       :total="total">
        </el-pagination>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import mixinImgUrl from '@/utils/mixin/imgUrl.js'
import { list as listReq, getPlugincate } from '@/api/plugin'
import { fenToYuanDiscount } from '../utils/common'
export default {
  name: 'Home',
  mixins: [mixinImgUrl],
  data () {
    return {
      query: {
        pageSize: 1000,
        pageNum: 1,
        pluginCateId: '',
        priceType: '', // '' - 全部 '1' - 免费 '2' - 付费
        pluginName: ''
      },
      list: [],
      total: 0,
      plugincate: []
    }
  },
  created () {
    // console.log(this.$route)
    const searchKey = this.$route.query.searchKey
    if (searchKey && searchKey !== '') {
      this.query.pluginName = searchKey
    }
    this.getList()
    this.getPlugincate().then(data => {
      this.plugincate = data
    })
  },
  methods: {
    fenToYuanDiscount,
    searchList (query) {
      Object.assign(this.query, query)
      this.getList()
    },
    isOfficial (e) {
      let isOfficial = ''
      if (e.target.checked) {
        isOfficial = '1'
      }
      this.searchList({ isOfficial: isOfficial })
    },
    jump (item) {
      this.$router.push({ path: '/showModule', query: { id: item.pluginId } })
    },
    getList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      listReq(this.query).then(res => {
        const { code, data: { list, total }, msg } = res
        if (code !== 0) {
          this.$message.error(msg)
          return []
        }
        this.list = list
        this.total = total
        return list
      }).finally(() => {
        loading.close()
      })
    },
    getPlugincate () {
      return getPlugincate().then(res => {
        const { code, data, msg } = res
        if (code !== 0) {
          this.$message.error(msg)
          return []
        }
        return Array.isArray(data) ? data : []
      })
    }
  }

}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  overflow: hidden;
}
.price{
  .yp{
    text-decoration: line-through;
    color: #999;
    font-size: 14px;
  }
}
.module-head-search {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 60px;
  padding: 30px 0;
  background-color: #3c3f69;
  text-align: center;

  .moduletitle {
    font-size: 36px;
    color: #FFF;
    margin-bottom: 10px;
  }

  .moduledesc {
    font-size: 22px;
    color: #FFF;
    margin-bottom: 20px;
  }

  .modulesearch {
    display: flex;
    justify-content: center;
    padding: 0 15px;

    .search-wrapper {
      position: relative;
      width: 100%;
      max-width: 500px;
      height: 44px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      background: #fff;

      input {
        width: calc(100% - 80px);
        height: 100%;
        padding: 0 20px;
        font-size: 14px;
        border: none;
        color: #333;
        outline: none;

        &::placeholder {
          color: #999;
        }
      }

      .module-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 100%;
        line-height: 44px;
        background: #155bd4;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        user-select: none;

        &:hover {
          background: #1248a5;
        }

        &:active {
          background: #0e3d8c;
        }
      }
    }
  }
}

.module-box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.clr {
  clear: both;
}

.active {
  color: #155bd4;
}

.search-head {
  cursor: pointer;
}

.mcard {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .module-head-search {
    margin-top: 0;

    .moduletitle {
      font-size: 24px;
    }

    .moduledesc {
      font-size: 16px;
    }

    .modulesearch {
      .search-wrapper {
        height: 40px;
      }

      .module-btn {
        line-height: 40px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .module-head-search {
    .modulesearch {
      .search-wrapper {
        display: flex;
        flex-direction: column;
        height: auto;
        background: transparent;
        box-shadow: none;

        input {
          width: 100%;
          height: 44px;
          border-radius: 4px;
          margin-bottom: 10px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
          background: #fff;
        }

        .module-btn {
          position: static;
          width: 100%;
          height: 44px;
          line-height: 44px;
          border-radius: 4px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
