import Vue from 'vue'
import './element'
import App from './App.vue'
import router from './router'
import store from './store'
import { fenToYuan, dateFormat } from './utils/common'
import './permission'

// 全局弹窗
import Popup from './components/popup/index'

// 全局过滤器
// 分转元
Vue.filter('fenToYuan', function (value) {
  return fenToYuan(value)
})

// 日期格式化
Vue.filter('dateFormat', function (value, format = 'YYYY-MM-DD') {
  return dateFormat(value, format)
})
Vue.config.productionTip = false
Vue.prototype.$dialog = Popup.install
Vue.prototype.BaseUrl = process.env.VUE_APP_BASE_API
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
